import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { ChecklistButton } from "@app/core/checklist/components/button/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { getActionMailMergeDataSetEnum } from "@app/core/new-mail-merge/dialogs/util";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { ViewDocumentsButton } from "@app/core/view/components/buttons/view-documents/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { BubbleUpType } from "@app/products/waste-water/[id]/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE, mapRecordType } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ActionForm } from "@common/pages/actions/[id]/components/child-screens/general/_index";
import { ActionsContactsTab } from "@common/pages/actions/[id]/components/reference-sidebar/contacts/_index";
import { ActionsDetailTab } from "@common/pages/actions/[id]/components/reference-sidebar/details/_index";
import { ActionsHistoryTab } from "@common/pages/actions/[id]/components/reference-sidebar/history/_index";
import { ActionStatusName } from "@common/pages/actions/[id]/components/until";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { ActionCancelMeetingButton } from "@common/pages/actions/components/action-bar/buttons/cancel-meeting/_index";
import { ActionCompleteButton } from "@common/pages/actions/components/action-bar/buttons/complete-action/_index";
import { useActionCompleteButtonStore } from "@common/pages/actions/components/action-bar/buttons/complete-action/store";
import { FurtherMeetingRequiredButton } from "@common/pages/actions/components/action-bar/buttons/further-meeting-required/_index";
import { ActionMeetingCompleteButton } from "@common/pages/actions/components/action-bar/buttons/meeting-complete/_index";
import { ReOpenActionButton } from "@common/pages/actions/components/action-bar/buttons/reopen-action/_index";
import { ReOpenMeetingButton } from "@common/pages/actions/components/action-bar/buttons/reopen-meeting/_index";
import { RescheduleActionButton } from "@common/pages/actions/components/action-bar/buttons/reschedule-action/_index";
import { ResendToActionOfficerButton } from "@common/pages/actions/components/action-bar/buttons/resend-to-action-officer/_index";
import { ReturnToSenderButton } from "@common/pages/actions/components/action-bar/buttons/return-to-sender/_index";
import { SendToActionOfficerButton } from "@common/pages/actions/components/action-bar/buttons/send-to-action-officer/_index";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageActions = observer(() => {
  const {
    isLoading,
    isLoadingParent,
    isLoadingAction,
    action,
    loadAction,
    onSubmit,
    responseLoadError,
    parent,
    isInactive,
  } = useActionStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Action,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  const { isLoadingLoadActionComplete } = useActionCompleteButtonStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const { settings } = useCommonCoreStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([action?.Action_ID])}`,
      LinkUrl: managePageUrl,
      LinkText: `Actions - Forms - Action - ${action?.Action_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.TownPlanning, //@TODO check later for all not only Town Planning PPR (can use parent)
      RecordType_ENUM: RECORDTYPE.TP_PPRApplication,
      Record_ID: action?.Action_ID ?? 0,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={parseInt(id)} recordType={RECORDTYPE.CORE_Action} />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={parseInt(id)} recordType={RECORDTYPE.CORE_Action} />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={parseInt(id)}
          recordType={RECORDTYPE.CORE_Action}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.CORE_Action} />
      ),
    },
  ];

  const ActionNavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  const headerTitle = (type: any) => {
    if (!isNil(type) && type?.Flag_IsReferral) {
      return "Referral";
    } else if (!isNil(type) && type?.Flag_IsMeeting) {
      return "Meeting";
    }
    return "Action";
  };

  const ancestorTypeEnum = useMemo(() => {
    if (isEmpty(action?._BubbleUps)) return;
    const ancestorType = action?._BubbleUps.find(
      (bubbleUp) => bubbleUp.BubbleUpType_ENUM === BubbleUpType.Ancestor
    )?.SourceIdentifier?._RecordSourceType_ENUM;
    return mapRecordType[ancestorType ?? ""];
  }, [action]);

  const checklistName = useMemo(
    () =>
      action?.ActionType?.ActionTypeChecklist_Label &&
      !isEmpty(action?.ActionType?.ActionTypeChecklist_Label)
        ? action?.ActionType?.ActionTypeChecklist_Label
        : undefined,
    [action?.ActionType?.ActionTypeChecklist_Label]
  );

  return (
    <>
      <Loading
        isLoading={
          isLoadingParent ||
          isLoadingAction ||
          isLoadingLoadActionComplete ||
          isLoadingPermission
        }
        isFullScreen
      />
      <FormNavigation title={"Action"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadAction(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            badge={
              action?.Status_ENUM ? ActionStatusName(action.Status_ENUM) : ""
            }
            title={headerTitle(action?.ActionType)}
          />
          <CCManagePageActionBar
            leftActions={[
              <ActionNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
                isDefaultActive={getBoolValueSetting(
                  settings[
                    ECorporateSettingsField
                      .CorporateSettings_Action_AlwaysPinOpenActionWorkflowBar
                  ]
                )}
              >
                <SendToActionOfficerButton />
                <ReturnToSenderButton />
                <RescheduleActionButton />
                <ResendToActionOfficerButton />
                <ActionCompleteButton
                  isDisabled={
                    !checkPermissions(FormAction.CRMS_COMPLETE_ACTION)
                    // Radium use this FormAction's name
                  }
                />
                <FurtherMeetingRequiredButton
                  id={parent?.id}
                  recordType={parent?.recordType ?? RECORDTYPE.CORE_Action}
                />
                <ReOpenActionButton />
                <ReOpenMeetingButton />
                <ActionMeetingCompleteButton />
                <ActionCancelMeetingButton />
                <ChecklistButton
                  disabled={isInactive}
                  recordSourceID={action?.Action_ID ?? 0}
                  checklistID={action?.ActionType?.ActionTypeChecklist_ID ?? 0}
                  checklistName={checklistName}
                  isFromAction
                />
              </ActionNavButton>,

              <ActionNavButton
                title={"Save"}
                isLoading={isLoading}
                disabled={
                  isLoading ||
                  !checkPermissions([
                    FormAction.CORE_ALLOW_EDIT,
                    FormAction.CORE_ALLOW_SAVE,
                  ])
                }
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,

              <ActionNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  bubbleUps={action?._BubbleUps}
                  recordType={RECORDTYPE.CORE_Action}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)
                  }
                />
                <AdditionalContactButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Action}
                  bubbleUps={action?._BubbleUps}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_CONTACT)
                  }
                />
                <AddAttachmentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Action}
                  bubbleUps={action?._BubbleUps}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
                  }
                />
                <AddCommentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Action}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
                  }
                />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  recordId={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Action}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
                  }
                />
                <AddDocumentButton
                  dataSetFilter={
                    getActionMailMergeDataSetEnum(ancestorTypeEnum) ??
                    MAILMERGEDATASET.CORE_Action
                  }
                  recordType={RECORDTYPE.CORE_Action}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={parseInt(id)}
                  bubbleUps={action?._BubbleUps}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
                  }
                />
              </ActionNavButton>,
              <ActionNavButton title={"View"} type="sub">
                <ViewDocumentsButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Action}
                />
              </ActionNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {action && <ActionForm />}
                <TabTable
                  initialPanels={listPanelBar}
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Action}
                  disabled={isInactive}
                />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <ActionsDetailTab /> },
                    { title: "Contacts", component: <ActionsContactsTab /> },
                    {
                      title: "History",
                      component: <ActionsHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
