import { setupPremisesPickSiteAddress } from "@app/products/hm/premises/[id]/api";
import {
  getNameOfPremises,
  getNameOfSiteAddress,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { checkSecondaryMode } from "@app/products/hm/premises/[id]/config";
import { HM_PREMISES_HANDLER_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IHMHandlerEventInitialData,
  PremiseHandlerRequest,
  Premises,
  PremisesRegisterLovs,
  PremisesUIControl,
  PremisesUpdateTriggers,
  SecondaryModeCheckField,
  Svc_FormAction_Premise,
} from "@app/products/hm/premises/[id]/model";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { getPropertyAddress } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/api";
import { isSuccessResponse } from "@common/apis/util";
import { AddressClassification } from "@common/constants/enumerations";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import {
  Address_BuildAddress,
  SetupAddress,
} from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isFunction } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useRef, useState } from "react";

export interface IHMPremisesGeneralSectionProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
}
export const GeneralSection = observer(
  ({ formRenderProps, isInactive = false }: IHMPremisesGeneralSectionProps) => {
    const { dataForms, setInitialDataForms } = useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;

    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    const uiControlStore = dataForms?.GeneralUIControl as PremisesUIControl;
    const lovs = dataForms?.GeneralFormLovs as PremisesRegisterLovs;

    const prevSiteAddressDisplayName = useRef<string>("");
    const prevPostalAddressDisplayName = useRef<string>("");

    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();
    const [isLoadingOpenDialog, setIsLoadingOpenDialog] = useState(false);

    // #region GET SETTING VALUES
    const isUseSimpleAddressDialogHM =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_SimpleAddressDialog]
      ) ?? false;

    const enableSecondaryMode = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
    );
    const sFieldMapping =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_TechOneFieldMapping]
      ) ?? "";
    // #endregion GET SETTING VALUES

    // #region GET LABEL
    const tradingNameLabel = useMemo(() => {
      if (
        valueGetter(getNameOfPremises("PremisesType"))?.Flag_SwimmingPool ||
        valueGetter(getNameOfPremises("PremisesType"))?.Flag_CoolingTower
      ) {
        return "Business name";
      }
      return "Trading name";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("PremisesType"))]);
    // #endregion GET LABEL

    const getInitialPremisesHandlerRequest = (
      formAction: Svc_FormAction_Premise
    ) => {
      return {
        FormAction: formAction,
        Premises: premisesObj,
        Args: {},
        IsFirstTimeLoad: false,
        UIControl: uiControlStore,
        PremiseLOVs: lovs,
      } as PremiseHandlerRequest;
    };

    const handleSiteAddressOpenDialog = async () => {
      if (!premisesObj) return;
      setIsLoadingOpenDialog(true);
      const setupAddress: SetupAddress = {
        Address: valueGetter(getNameOfPremises("SiteAddress")),
        ClassificationFilters: [AddressClassification.Property],
        IsHasFlag_ForceUse_StreetType: false,
      };
      const response = await setupPremisesPickSiteAddress(setupAddress);
      setIsLoadingOpenDialog(false);
      if (isSuccessResponse(response) && response?.data) {
        setInitialBuildAddress(response?.data?.ReturnObj);
      } else {
        pushNotificationPortal({
          autoClose: false,
          title: "Setup address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };
    const handleChangePremisesAddressHandler = (
      buildAddress: Address_BuildAddress | null
    ) => {
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickPremisesAddress
        );
      if (buildAddress) {
        premisesHandlerRequest.Args = {
          SiteAddress: buildAddress?.Address,
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select premises address failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValuePremisesAddress = () => {
      if (uiControlStore) {
        prevSiteAddressDisplayName.current = uiControlStore?.LitAddress?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitAddress: {
              ...uiControlStore?.LitAddress,
              Value: premisesObj?.SiteAddress?.Formatted_SingleLine,
            },
          },
        });
      }
    };
    const restoreDisplayValuePremisesAddress = () => {
      if (
        uiControlStore &&
        isHTML(prevSiteAddressDisplayName?.current) &&
        valueGetter(getNameOfPremises("SiteAddress"))
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitAddress: {
              ...uiControlStore?.LitAddress,
              Value: prevSiteAddressDisplayName.current,
            },
          },
        });
      }
    };
    const requiredValidatorPremisesAddress = useCallback(() => {
      return requiredValidator(uiControlStore?.LitAddress?.Value);
    }, [uiControlStore?.LitAddress?.Value]);
    // #endregion Premises Address Field

    // #region Postal address Field
    const handleOpenPostalAddressDialog = async (
      setIsShowDialog?: (value: React.SetStateAction<boolean>) => void
    ) => {
      if (!premisesObj) return;
      setIsLoadingOpenDialog(true);
      const setupAddress: SetupAddress = {
        Address: valueGetter(getNameOfPremises("PostalAddress")),
        ClassificationFilters: [
          AddressClassification.International,
          AddressClassification.Generic,
          AddressClassification.POBox,
        ],
        IsHasFlag_ForceUse_StreetType: false,
      };
      const response = await setupPremisesPickSiteAddress(setupAddress);
      setIsLoadingOpenDialog(false);
      if (isSuccessResponse(response) && response?.data) {
        setInitialBuildAddress(response?.data?.ReturnObj);

        if (setIsShowDialog && isFunction(setIsShowDialog))
          setIsShowDialog(true);
      } else {
        pushNotificationPortal({
          autoClose: false,
          title: "Setup address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };
    const handleChangePostalAddressHandler = (
      buildAddress: Address_BuildAddress | null
    ) => {
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickPostalAddress
        );
      if (buildAddress) {
        premisesHandlerRequest.Args = {
          PostalAddress: buildAddress?.Address,
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: `Select postal address failed.`,
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValuePostalAddress = () => {
      if (uiControlStore) {
        prevPostalAddressDisplayName.current =
          uiControlStore?.LitPostalAddress?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitPostalAddress: {
              ...uiControlStore?.LitPostalAddress,
              Value: premisesObj?.PostalAddress?.Formatted_SingleLine,
            },
          },
        });
      }
    };
    const restoreDisplayValuePostalAddress = () => {
      if (
        uiControlStore &&
        isHTML(prevPostalAddressDisplayName?.current) &&
        valueGetter(getNameOfPremises("PostalAddress"))
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitPostalAddress: {
              ...uiControlStore?.LitPostalAddress,
              Value: prevPostalAddressDisplayName.current,
            },
          },
        });
      }
    };
    // #endregion Postal address Field

    // #region Secondary Mode
    const checkSecondaryModeTxtTradingName = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.TxtTradingName
    );

    const checkSecondaryModeLbtAddress = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.LbtAddress
    );
    // #endregion Secondary Mode

    const updateSaveTriggers = (triggers: PremisesUpdateTriggers) => {
      let saveTriggers: PremisesUpdateTriggers[] =
        valueGetter(getNameOfPremises("SaveTriggers")) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(getNameOfPremises("SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    // #region UI
    return (
      <>
        {isLoadingOpenDialog && <Loading isLoading isFullScreen />}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={tradingNameLabel} isMandatory />
              <Field
                component={CCInput}
                name={getNameOfPremises("TradingName")}
                validator={requiredValidator}
                disabled={isInactive || checkSecondaryModeTxtTradingName}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Premises address" isMandatory />
              <Field
                uniqueKey="PremisesSiteAddress"
                name={getNameOfPremises("SiteAddress")}
                component={PropertyDetail}
                initialData={initialBuildAddress}
                placeholder={"Select premises address"}
                formRenderProps={formRenderProps}
                validator={requiredValidatorPremisesAddress}
                isSearchPropertyAddresses={true}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: "Select premises address failed.",
                    description: error,
                    autoClose: false,
                  });
                }}
                value={uiControlStore?.LitAddress?.Value || ""}
                onChangeEventHandler={handleChangePremisesAddressHandler}
                onSubmit={handleChangePremisesAddressHandler}
                onButtonClick={handleSiteAddressOpenDialog}
                removeDisplayValue={removeDisplayValuePremisesAddress}
                restoreDisplayValue={restoreDisplayValuePremisesAddress}
                disabled={isInactive || checkSecondaryModeLbtAddress}
                disabledButton={isInactive || checkSecondaryModeLbtAddress}
                updateSaveTriggers={() => {
                  updateSaveTriggers(PremisesUpdateTriggers.UpdateSiteAddress);
                }}
                isShowFullResultSearch
                isHaveStreetLookup
                forceIsUseSimpleAddressDialog={isUseSimpleAddressDialogHM}
              />
            </div>
            <div className="cc-field">
              <CCLabel
                title="Postal address"
                customTooltip={
                  <CCTooltip
                    type="custom"
                    position="right"
                    content="Set postal address same as premise address."
                  >
                    <i
                      onClick={async () => {
                        const assessmentID = await getPropertyAddress(
                          valueGetter(getNameOfSiteAddress("Assessment_ID"))
                        );
                        handleChangePostalAddressHandler({
                          Address: assessmentID?.data,
                        } as Address_BuildAddress);
                      }}
                      className="fas fa-clone ml-1 text-accent cc-clone-icon"
                    />
                  </CCTooltip>
                }
              />
              <Field
                uniqueKey="HMPremisesPostalAddress"
                name={getNameOfPremises("PostalAddress")}
                component={PropertyDetail}
                isShowFullResultSearch
                isSearchPropertyAddresses
                isHaveStreetLookup
                formRenderProps={formRenderProps}
                initialData={initialBuildAddress}
                placeholder="Select location"
                onButtonClick={handleOpenPostalAddressDialog}
                onBeforeOpenDialog={handleOpenPostalAddressDialog}
                onChangeEventHandler={handleChangePostalAddressHandler}
                onSubmit={handleChangePostalAddressHandler}
                value={uiControlStore?.LitPostalAddress?.Value || ""}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: `Select postal address failed.`,
                    description: error,
                    autoClose: false,
                  });
                }}
                updateSaveTriggers={() => {
                  updateSaveTriggers(
                    PremisesUpdateTriggers.UpdatePostalAddress
                  );
                }}
                removeDisplayValue={removeDisplayValuePostalAddress}
                restoreDisplayValue={restoreDisplayValuePostalAddress}
                disabled={isInactive}
                disabledButton={isInactive}
                forceIsUseSimpleAddressDialog={isUseSimpleAddressDialogHM}
              />
            </div>
          </div>
        </section>
      </>
    );
    // #endregion UI
  }
);
