import { getHMPremiseById } from "@app/products/hm/premises/[id]/api";
import {
  Premises_Status,
  PremisesUIControl,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { APIResponse } from "@common/apis/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IIdentityPacket } from "@common/models/identityPacket";
import { nameOfIKeyValuePacket } from "@common/pages/settings/lookups/inspection-types/_id/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { getDropdownValue } from "@common/utils/common";
import {
  capitalizeFirstLetter,
  formatDisplayValue,
  formatOSFees,
} from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import "./_index.scss";

export const PremiseDetailsTab = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const { cancelToken } = useCancelRequest();
  const { settings } = useCommonCoreStore();

  const [premisesObj, setPremisesObj] = useState<Svc_Premises | undefined>();

  const osFeeLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_OSFeeLabel]
  );

  const managePremiseSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const id: number = initialData;
          return getHMPremiseById(id, cancelToken());
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<IIdentityPacket<Svc_Premises>> =
            dataFromApi;
          const premiseData = response?.data?.ReturnObj;
          setPremisesObj(premiseData);
        },
      },
    ],
  });

  useEffect(() => {
    if (!isNil(lastSelectedId)) {
      managePremiseSlider.fetchApi({
        initialData: parseInt(lastSelectedId),
      });
    } else {
      setPremisesObj(dataForms?.GeneralForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForms?.GeneralForm, lastSelectedId]);

  if (managePremiseSlider?.isFetching) return <Loading isLoading />;
  if (!premisesObj) return <NoData />;

  const proprietorAbnOrAcn =
    [
      premisesObj?.Premises?.Proprietor?.Contact?.ABN,
      premisesObj?.Premises?.Proprietor?.Contact?.ACN,
    ]
      .filter((x) => !isNil(x))
      .join(" / ") ?? "";

  const getInspectionFrequency =
    getDropdownValue(
      premisesObj?.Premises?.InspectionFrequency ?? 0,
      dataForms.GeneralFormLovs?.InspectionFrequency ?? [],
      nameOfIKeyValuePacket("Key")
    )?.Value ?? "";

  const getAuditFrequency =
    getDropdownValue(
      premisesObj?.Premises?.AuditFrequency ?? 0,
      dataForms.GeneralFormLovs?.AuditFrequency ?? [],
      nameOfIKeyValuePacket("Key")
    )?.Value ?? "";

  const uiControlStore = dataForms?.GeneralUIControl as PremisesUIControl;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <div className="cc-main-title-sidebar">
          <div>{premisesObj?.Premises?.TradingName}</div>
          <div>{premisesObj?.Premises?.SiteAddress?.Formatted_SingleLine}</div>
        </div>
        <ProductReferenceRow primary />
        <PanelBarItem
          title={<ProductReferenceHeading title="Premises" />}
          expanded
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title="Registration number:"
              value={premisesObj?.Premises?.RegistrationNumber}
            />
            <ProductReferenceRow
              title="Status:"
              value={
                premisesObj?.Premises?.Status_ENUM
                  ? Premises_Status[premisesObj?.Premises?.Status_ENUM]
                  : ""
              }
              primary
            />
            <ProductReferenceRow
              title="Registered from:"
              value={
                premisesObj?.Premises?.Date_RegistrationFrom
                  ? formatDisplayValue(
                      premisesObj?.Premises?.Date_RegistrationFrom,
                      DATE_FORMAT.DATE
                    )
                  : ""
              }
            />
            <ProductReferenceRow
              title="Registered to:"
              value={
                premisesObj?.Premises?.Date_RegistrationTo
                  ? formatDisplayValue(
                      premisesObj?.Premises?.Date_RegistrationTo,
                      DATE_FORMAT.DATE
                    )
                  : ""
              }
            />
          </ProductReferenceBlock>
        </PanelBarItem>
        <PanelBarItem title={<ProductReferenceHeading title="Contacts" />}>
          <ProductReferenceBlock>
            <ProductReferenceRow
              title="Proprietor - name:"
              value={premisesObj?.Premises?.Proprietor?.Contact?.DisplayName}
            />
            <ProductReferenceRow
              title="Proprietor - Phone:"
              value={premisesObj?.Premises?.Proprietor?.Contact?.WorkPhone}
            />
            <ProductReferenceRow
              title="Proprietor - Email:"
              value={premisesObj?.Premises?.Proprietor?.Contact?.Email}
            />
            <ProductReferenceRow
              title="Proprietor - ABN/ACN:"
              value={proprietorAbnOrAcn}
            />
            <ProductReferenceRow
              title="Primary Contact - name:"
              value={
                premisesObj?.Premises?.PrimaryContact?.Contact?.DisplayName
              }
            />
            <ProductReferenceRow
              title="Primary Contact - phone:"
              value={premisesObj?.Premises?.PrimaryContact?.Contact?.WorkPhone}
            />
            <ProductReferenceRow
              title="Primary contact - email:"
              value={premisesObj?.Premises?.PrimaryContact?.Contact?.Email}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
        <PanelBarItem title={<ProductReferenceHeading title="Inspection" />}>
          <ProductReferenceBlock>
            <ProductReferenceRow
              title="Inspection frequency:"
              value={getInspectionFrequency}
            />
            <ProductReferenceRow
              title="Last inspection date:"
              value={
                premisesObj?.Premises?.LastInspection
                  ? formatDisplayValue(
                      premisesObj?.Premises?.LastInspection,
                      DATE_FORMAT.DATE
                    )
                  : "Not yet inspected"
              }
            />
            <ProductReferenceRow
              title="Last inspection type:"
              value={
                uiControlStore?.LbtLastInspectionType?.Value ||
                premisesObj?.InspectionTypeName ||
                "Not yet inspected"
              }
            />
            <ProductReferenceRow
              title="Last inspection compliance:"
              value={
                premisesObj?.Premises?.LastInspectionResult ||
                "Not yet inspected"
              }
            />
            <ProductReferenceRow
              title="Next inspection date:"
              value={
                premisesObj?.Premises?.NextInspection
                  ? formatDisplayValue(
                      premisesObj?.Premises?.NextInspection,
                      DATE_FORMAT.DATE
                    )
                  : "Not yet inspected"
              }
            />
            <ProductReferenceRow
              title="Audit frequency:"
              value={getAuditFrequency}
            />
            <ProductReferenceRow
              title="Last audit date:"
              value={
                premisesObj?.Premises?.LastAudit
                  ? formatDisplayValue(
                      premisesObj?.Premises?.LastAudit,
                      DATE_FORMAT.DATE
                    )
                  : "Not yet audited"
              }
            />
            <ProductReferenceRow
              title="Last audit type:"
              value={
                uiControlStore?.LbtLastAuditType?.Value ||
                premisesObj?.LbtLastAuditType ||
                "Not yet audited"
              }
            />
            <ProductReferenceRow
              title="Last audit compliance:"
              value={
                uiControlStore?.LbtLastAuditCompliance?.Value ||
                premisesObj?.LbtLastAuditCompliance ||
                "Not yet audited"
              }
            />
            <ProductReferenceRow
              title="Next audit date:"
              value={
                premisesObj?.Premises?.NextAudit
                  ? formatDisplayValue(
                      premisesObj?.Premises?.NextAudit,
                      DATE_FORMAT.DATE
                    )
                  : "Not yet audited"
              }
            />
          </ProductReferenceBlock>
        </PanelBarItem>
        <PanelBarItem title={<ProductReferenceHeading title="OS fees" />}>
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={
                isNil(capitalizeFirstLetter(osFeeLabel))
                  ? "OS fees:"
                  : `${capitalizeFirstLetter(osFeeLabel)}:`
              }
              value={
                premisesObj?.OSFee
                  ? formatDisplayValue(
                      premisesObj?.OSFee,
                      CURRENCY_FORMAT.CURRENCY1
                    )
                  : "$0"
              }
              format="currency"
              style={formatOSFees(premisesObj?.OSFee ?? 0)}
              isPropertyStyle={false}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>

      {premisesObj?.Premises?.Alert && (
        <>
          <hr />
          <ProductReferenceRow
            badge
            warning
            value={premisesObj?.Premises?.Alert}
          />
        </>
      )}
    </ProductReferenceBody>
  );
});
