import {
  getNameOfPremises,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { checkSecondaryMode } from "@app/products/hm/premises/[id]/config";
import {
  Premises,
  SecondaryModeCheckField,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import {
  AUSPOSTFORMAT,
  FINANCEMETHOD,
  HEALTHMANAGER_CONFIGURATIONMODE,
} from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { optionDebtorNumber } from "@common/input-pickers/debtor-number/config";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  capitalizeFirstLetter,
  formatDisplayValue,
  formatOSFees,
} from "@common/utils/formatting";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isString } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";

export interface IHMPremisesTypeSectionProps {
  isInactive?: boolean;
  allowChangePremisesType?: boolean;
  formRenderProps: FormRenderProps;
}

const nameOfDebtorNumber = nameOfFactory<RefNumberUsage>();

export const PremisesTypeSection2 = observer(
  ({ formRenderProps, isInactive = false }: IHMPremisesTypeSectionProps) => {
    // #region STORES
    const { dataForms } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    // #endregion STORES

    // #region FORM PROPS
    const { valueGetter, onChange } = formRenderProps;
    const getValuePremises = (name: keyof Premises) =>
      valueGetter(getNameOfPremises(name));
    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    // #endregion FORM PROPS

    // #region VARIABLES
    const isSuperAdmin = dataForms?.IsSuperAdmin;
    // #endregion VARIABLES

    // #region GET SETTING VALUES
    const ausPostFormat = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AusPostFormat]
    );
    const debtorNumberLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    );
    const isDebtorNumberMandatory = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberMandatory]
    );
    const isEnableDebtorUserSelection = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_EnableDebtorUserSelection
      ]
    );
    const isShowBPAYInfo = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowBPAYInfo]
    );
    const isStreatrader = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_IsStreatrader]
    );
    const enableSecondaryMode = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
    );
    const sFieldMapping =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_TechOneFieldMapping]
      ) ?? "";
    const configurationMode = getNumberValueSetting(
      settings[ECorporateSettingsField.HealthManager_ConfigurationMode]
    );
    const autoGenerateDebtorNumber = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_FinanceAllocateDebtorNumber
      ]
    );
    // #endregion GET SETTING VALUES

    // #region Secondary Mode
    const checkSecondaryModeTxtDebtorNo = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.TxtDebtorNo,
      configurationMode
    );
    // #endregion Secondary Mode

    //#region HANDLE ON CHANGE
    const isHasProprietor = !!premisesObj?.Proprietor?.Contact?.Contact_ID;
    const onDebtorNumberButtonClick = () => {
      pushNotificationPortal({
        title: "Please select Proprietor before selecting Debtor number.",
        type: "warning",
      });
    };
    const handleOnChangeDebtorNumberValue = (value: RefNumberUsage) => {
      onChange(getNameOfPremises("DebtorNumber"), {
        value: isString(value) ? value : value?.Sys_DebtorNumber ?? "",
      });
    };
    //#endregion HANDLE ON CHANGE
    const premisesGeneralForm = dataForms?.GeneralForm as Svc_Premises;
    const osFeeLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_OSFeeLabel]
    );

    const enableUpToGlassFinance = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
    );
    const financeMethod =
      getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
      ) ?? FINANCEMETHOD.None;
    const checkUpToGlassEnabled = useMemo(() => {
      return enableUpToGlassFinance && financeMethod === FINANCEMETHOD.Direct;
    }, [enableUpToGlassFinance, financeMethod]);
    const enableMultiLineInvoice = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
    );

    return (
      <>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel
                title={debtorNumberLabel ?? "Debtor number"}
                isMandatory={isDebtorNumberMandatory}
              />
              {isEnableDebtorUserSelection ? (
                <Field
                  name={getNameOfPremises("DebtorNumber")}
                  nameDisplay={nameOfDebtorNumber("Sys_DebtorNumber")}
                  placeholder={"Debtor number"}
                  allowCustom={
                    !(
                      !isSuperAdmin &&
                      autoGenerateDebtorNumber &&
                      configurationMode !==
                        HEALTHMANAGER_CONFIGURATIONMODE.WATER_AUTHORITY
                    ) && !checkSecondaryModeTxtDebtorNo
                  }
                  component={InputPickerSearch}
                  onChange={handleOnChangeDebtorNumberValue}
                  options={
                    optionDebtorNumber({
                      contactId: parseInt(
                        valueGetter(
                          `${getNameOfPremises(
                            "Proprietor"
                          )}.Contact.Contact_ID`
                        )
                      ),
                      recordId: premisesObj?.Premises_ID,
                      recordType: RECORDTYPE.HealthManager_Premises,
                    }) ?? []
                  }
                  onOpenDialogClick={
                    isHasProprietor ? undefined : onDebtorNumberButtonClick
                  }
                  validator={
                    isDebtorNumberMandatory ? requiredValidator : undefined
                  }
                  disabled={isInactive}
                />
              ) : (
                <Field
                  name={getNameOfPremises("DebtorNumber")}
                  component={CCInput}
                  placeholder={"Debtor number"}
                  disabled={
                    isInactive ||
                    checkSecondaryModeTxtDebtorNo ||
                    (!isSuperAdmin &&
                      autoGenerateDebtorNumber &&
                      configurationMode !==
                        HEALTHMANAGER_CONFIGURATIONMODE.WATER_AUTHORITY)
                  }
                  validator={
                    isDebtorNumberMandatory ? requiredValidator : undefined
                  }
                />
              )}
            </div>
            <div className="cc-field">
              <CCLabel title="File number" />
              <Field
                name={getNameOfPremises("FileNumber")}
                component={CCInput}
                disabled={isInactive}
              />
              {/*TODO: Implement Sharepoint URL later*/}
              {/* {premisesObj?.FileNumber && (
              <div className="cc-label-field cc-link-field">
                <a
                  href={generateSharepointURL(
                    settings,
                    RECORDTYPE.HealthManager_Premises,
                    premisesObj?.FileNumber
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cc-grid-link-text cc-label-field"
                >
                  {premisesObj?.FileNumber ?? ""}
                  <i className="fas fa-external-link-alt fa-xs cc-external-link-icon-sharepoint " />
                </a>
              </div>
            )} */}
            </div>

            <div className="cc-field">
              <CCLabel
                title="Reference number"
                isMandatory={ausPostFormat === AUSPOSTFORMAT.Internal}
              />
              <Field
                name={getNameOfPremises("ReferenceNumber")}
                component={CCInput}
                disabled={isInactive}
                validator={
                  ausPostFormat === AUSPOSTFORMAT.Internal
                    ? requiredValidator
                    : undefined
                }
                min={0}
              />
            </div>

            {isShowBPAYInfo && (
              <div className="cc-field">
                <CCLabel title="BPAY reference" />
                <CCValueField
                  name={getNameOfPremises("BPayReference")}
                  value={
                    ausPostFormat === AUSPOSTFORMAT.Internal
                      ? getValuePremises("ReferenceNumber")
                      : getValuePremises("BPayReference")
                  }
                />
              </div>
            )}

            <CCValueField
              label={capitalizeFirstLetter(osFeeLabel) ?? "OS fees"}
              value={
                premisesGeneralForm?.OSFee
                  ? formatDisplayValue(
                      premisesGeneralForm?.OSFee,
                      CURRENCY_FORMAT.CURRENCY1
                    )
                  : "$0"
              }
              style={
                premisesGeneralForm?.OSFee
                  ? formatOSFees(premisesGeneralForm?.OSFee)
                  : undefined
              }
              format={CURRENCY_FORMAT.CURRENCY1}
            />
            {checkUpToGlassEnabled && enableMultiLineInvoice && (
              <CCValueField
                label="Premises balance"
                value={premisesGeneralForm?.PremisesBalance}
                style={
                  premisesGeneralForm?.PremisesBalance
                    ? formatOSFees(premisesGeneralForm?.PremisesBalance)
                    : undefined
                }
                format={CURRENCY_FORMAT.CURRENCY1}
              />
            )}

            {isStreatrader && (
              <>
                <div className="cc-field">
                  <CCLabel title="Streatrader" />
                  <Field
                    name={getNameOfPremises("Flag_Streatrader")}
                    component={CCSwitch}
                    checked={getValuePremises("Flag_Streatrader")}
                    disabled={isInactive}
                  />
                </div>
                {getValuePremises("Flag_Streatrader") && (
                  <div className="cc-field">
                    <CCLabel title="Streatrader reference" />
                    <Field
                      name={getNameOfPremises("StreatraderRef")}
                      component={CCInput}
                      disabled={isInactive}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </section>
      </>
    );
  }
);
