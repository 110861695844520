import { KeyValuePair } from "@app/core/documents/model";
import { Contact_Relation } from "@app/core/inspections/[id]/model";
import { Register } from "@app/core/location-register/[id]/model";
import {
  DTOBaseClass_Standard,
  ElementControl,
} from "@app/products/crms/[id]/model";
import {
  Contact_RelationLightWeight,
  PCBACategories,
  PremisesType,
  RenewalWorkflowStatus,
  VehicleVendorType,
  WasteWater,
} from "@app/products/hm/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import {
  DEBTOREXPORTED,
  PAYMENTOPTIONS,
  YESNONA,
} from "@common/constants/enumerations";
import { Address } from "@common/input-pickers/address/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";

// #region PREMISES DTO
export interface Premises extends DTOBaseClass_Standard {
  Premises_ID: number;
  RegistrationNumber: string;
  ReferenceNumber: string;
  BPayReference: string;
  FileNumber: string;
  DebtorNumber: string;
  StreatraderRef: string;
  TradingName: string;
  Comments: string;
  Conditions: string;
  Foods: string;
  Alert: string;
  Alerts: string[];
  BarcodeReference: string;
  Migration_ID: string;
  ClosureReason: string;
  PreviousCategory: string;
  MailMergeXML: string;
  Date_Lodged: Date | null;
  Date_Issued: Date | null;
  Date_Renewed: Date | null;
  Date_RegistrationFrom: Date | null;
  Date_RegistrationTo: Date | null;
  Date_LastTransfer: Date | null;
  Date_Closed: Date | null;
  Date_ConditionsDue: Date | null;
  Date_ConditionsComplied: Date | null;
  Date_ConsentToDisclose: Date | null;
  Date_FSPLetterOfEngagement: Date | null;
  Renewal_FormReceived: Date | null;
  Renewal_FormReceivedPrevious: Date | null;
  RenewalRefNumber: string;
  Date_BusinessCommenced: Date | null;
  LiquorLicenceRefNo: string;
  ClosureType_KWD: number | null;
  ClosureType_Name: string;
  LiquorLicenceType_KWD: number | null;
  LiquorLicenceType_Name: string;
  PrivateWaterSupplyType_KWD: number | null;
  PrivateWaterSupplyType_Name: string;
  FSPTemplate_KWD: number | null;
  FSPTemplate_Name: string;
  SewageTreatmentPlant_KWD: number | null;
  SewageTreatmentPlant_Name: string;
  DW_RMP_KWD: number | null;
  DW_RMP_Name: string;
  DW_Standard_RMP_KWD: number | null;
  DW_Standard_RMP_Name: string;
  DW_EColi_Monitor_KWD: number | null;
  DW_EColi_Monitor_Name: string;
  DW_Chem_Monitor_KWD: number | null;
  DW_Chem_Monitor_Name: string;
  DW_Source_KWDs: number[];
  DW_Source_Names: string;
  DW_Treatment_KWDs: number[];
  DW_Treatment_Names: string;
  Status_ENUM: Premises_Status;
  Status_Name: string;
  DueStatus: string;
  WasteWater_ENUM: WasteWater | null;
  WasteWater_Name: string;
  Vehicle_Vendor_Type_ENUM: VehicleVendorType | null;
  Vehicle_Vendor_Type_Name: string;
  InspectionFrequency: number | null;
  AuditFrequency: number | null;
  AnnualVolume: number | null;
  TWVolume: number | null;
  RiskScore: number | null;
  RenewalWorkflowStatus_ENUM: RenewalWorkflowStatus | null;
  RenewalWorkflowStatus_Name: string;
  Portal_PaymentOption_ENUM: PAYMENTOPTIONS | null;
  Flag_Conditional: boolean;
  Flag_RequiresCertificate: boolean;
  Flag_RenewalFeeCreated: boolean;
  Flag_PenaltyFeeCreated: boolean;
  Flag_RenewalApproved: boolean;
  Flag_Streatrader: boolean;
  Flag_PendingTransfer: boolean;
  Flag_DebtorExported: boolean;
  Flag_EatInFacility: boolean;
  Flag_OutdoorDining: boolean | null;
  Flag_TobaccoRetail: boolean;
  Flag_ECigaretteProducts: boolean | null;
  Flag_ShishaProducts: boolean | null;
  Flag_VendingMachine: boolean;
  Flag_LiquorLicence: boolean;
  Flag_PrivateWaterSupply: boolean;
  Flag_BingoCentre: boolean;
  Flag_LicencedNonGaming: boolean;
  Flag_GamingVenue: boolean;
  Flag_ConsentToDisclose: boolean;
  Flag_AppointmentRequiredForInspection: boolean | null;
  Flag_RenewalNoticeSent: boolean | null;
  Flag_DeptOfEducation: boolean;
  Flag_HomeBusiness: boolean | null;
  Stall_Overall_EventName: string;
  Stall1_Location: string;
  Stall2_Location: string;
  Stall3_Location: string;
  Stall4_Location: string;
  Stall5_Location: string;
  Stall6_Location: string;
  Stall7_Location: string;
  Stall8_Location: string;
  Stall9_Location: string;
  Stall10_Location: string;
  Stall11_Location: string;
  Stall12_Location: string;
  Stall_Overall_PeriodCommence: Date | null;
  Stall_Overall_PeriodConclude: Date | null;
  Stall_Overall_Expires: Date | null;
  Stall1_PeriodCommence: Date | null;
  Stall1_PeriodConclude: Date | null;
  Stall2_PeriodCommence: Date | null;
  Stall2_PeriodConclude: Date | null;
  Stall3_PeriodCommence: Date | null;
  Stall3_PeriodConclude: Date | null;
  Stall4_PeriodCommence: Date | null;
  Stall4_PeriodConclude: Date | null;
  Stall5_PeriodCommence: Date | null;
  Stall5_PeriodConclude: Date | null;
  Stall6_PeriodCommence: Date | null;
  Stall6_PeriodConclude: Date | null;
  Stall7_PeriodCommence: Date | null;
  Stall7_PeriodConclude: Date | null;
  Stall8_PeriodCommence: Date | null;
  Stall8_PeriodConclude: Date | null;
  Stall9_PeriodCommence: Date | null;
  Stall9_PeriodConclude: Date | null;
  Stall10_PeriodCommence: Date | null;
  Stall10_PeriodConclude: Date | null;
  Stall11_PeriodCommence: Date | null;
  Stall11_PeriodConclude: Date | null;
  Stall12_PeriodCommence: Date | null;
  Stall12_PeriodConclude: Date | null;
  Vehicle_Description: string;
  Vehicle_1_Make: string;
  Vehicle_1_RegNo: string;
  Vehicle_1_Colour: string;
  Vehicle_1_FoodToBeCarried: string;
  Vehicle_2_Make: string;
  Vehicle_2_RegNo: string;
  Vehicle_2_Colour: string;
  Vehicle_2_FoodToBeCarried: string;
  Vehicle_3_Make: string;
  Vehicle_3_RegNo: string;
  Vehicle_3_Colour: string;
  Vehicle_3_FoodToBeCarried: string;
  Pool_Description: string;
  Pool_TurnOver: string;
  Pool_Siting: string;
  Pool_DisinfectantDosing: string;
  Pool_WaterTreatment: string;
  Pool_Filtration: string;
  Pool_WaterDisposal: string;
  Pool_Flag_CyanuricAcid: boolean | null;
  Pool_Flag_SharedCirculation: boolean | null;
  Pool_Flag_Contracted: boolean | null;
  Pool_Volume: number | null;
  NoOfTables: number | null;
  NoOfBeds: number | null;
  NoOfUnits: number | null;
  NoOfRooms: number | null;
  NoOfEmployees: number | null;
  NoOfShortTermSites: number | null;
  NoOfLongTermSites: number | null;
  NoOfOccupants: number | null;
  TOB_NoOfPOS: number | null;
  TOB_OtherPOS: number | null;
  TOB_Categories: string;
  GV_NoOfRooms: number | null;
  LNG_NoOfRooms: number | null;
  VM_NoOfVendingMachine: number | null;
  VM_LicenceDisplayed: boolean | null;
  VM_PresNoticeDisplayed: boolean | null;
  VM_Location: string;
  PCAndBACategories: PCBACategories;
  _PCAndBACategories?: KeyValuePair<string, string>[];
  _PCAndBA: string;
  PCAndBA: string;
  VM_AdvertisingLocation: string;
  VM_MobileVanRegNo: string;
  ArchiveBoxNumber: string;
  TradingHours: string;
  NoOfFoodhandlers: number | null;
  BusinessTier_KWD: number | null;
  BusinessTier_Name: string;
  Flag_OutdoorArea: boolean | null;
  PremisesType: PremisesType;
  PremisesType_ID: number | null;
  RenewalFee_ID: number | null;
  Proprietor: Contact_Relation;
  Proprietor_Light: Contact_RelationLightWeight;
  Proprietor_RID: number | null;
  FSPContact: Contact_Relation;
  FSPContact_Light: Contact_RelationLightWeight;
  FSPContact_RID: number | null;
  PrimaryContact: Contact_Relation;
  PrimaryContact_Light: Contact_RelationLightWeight;
  PrimaryContact_RID: number | null;
  Pool_Operator: Contact_Relation;
  Pool_Operator_RID: number | null;
  Pool_Contractor: Contact_Relation;
  Pool_Contractor_RID: number | null;
  SiteAddress: Address;
  SiteAddress_ID: number | null;
  SiteAddress_AssessmentNo: string;
  PostalAddress: Address;
  PostalAddress_ID: number | null;
  Officer: SiteUser;
  Officer_ID: number | null;
  LGA_KWD: number | null;
  LGA_Name: string;
  Date_CopyofLastInspectionReport: Date | null;
  AddressHomeJurisdiction: string;
  Flag_ViewInPortal: boolean | null;
  Flag_RenewalSentToPortal: boolean | null;
  Flag_RenewalSubmittedFromPortal: boolean | null;
  Flag_TransferRequestFromPortal: boolean | null;
  Flag_MultiTenancy: boolean;
  MultiTenancy_Register_ID: number | null;
  MultiTenancyRegister: Register;
  PremisesBusinessActivity_ID: number | null;
  PremisesBusinessActivity: PremisesBusinessActivity;
  Flag_IsAccredited: boolean | null;
  Accreditation_Body: string;
  MyProperty: Date | null;
  Accreditation_Expires: Date | null;
  Flag_FacilitiesOnSite: boolean | null;
  Sterilisation_ContactName: string;
  Sterilisation_ContactPhone: string;
  Sterilisation_ContactFax: string;
  Sterilisation_ABN: string;
  Sterilisation_OffsiteCompany: string;
  IC_Officer: string;
  IC_ContactPhone: string;
  IC_ContactFax: string;
  IC_PercentTimeForIC: number | null;
  Flag_IC_ProcedureDev: boolean | null;
  IC_Last_Reviewed: Date | null;
  Food_ABN: string;
  Food_Company: string;
  Flag_FoodPrepOffSite: boolean | null;
  Food_ContactName: string;
  Food_ContactPhone: string;
  Food_ContactFax: string;
  Waste_ABN: string;
  Waste_Company: string;
  Waste_ContactName: string;
  Waste_ContactPhone: string;
  Waste_ContactFax: string;
  Waste_DateReportSent: Date | null;
  Flag_FastChoice: boolean | null;
  Flag_FSSRequired: boolean | null;
  Flag_ScoresEndorse: boolean | null;
  AD_ApplicationReceived: Date | null;
  AD_FormIDReceived: boolean | null;
  AD_FormIDAttachedDB: boolean | null;
  AD_ApplicationChecked: boolean | null;
  AD_ProofProvidedFeeExmp: YESNONA;
  AD_ASICExtractProvided: YESNONA;
  AD_ApplicationFwdToPHO: Date | null;
  AD_AssessmentOfficer: number | null;
  AD_AssessmentOfficerDisplayName: string;
  AD_PlansSubmitted: Date | null;
  AD_PlansApproved: Date | null;
  AD_ApplicationApproved: boolean | null;
  AD_DateResolved: Date | null;
  IC_ICOfficer: string;
  IC_AccredProgram: string;
  IC_Expires: Date | null;
  NextInspection: Date | null;
  LastInspection: Date | null;
  LastInspectionResult: string;
  NextAudit: Date | null;
  LastAudit: Date | null;
  LastSampled: Date | null;
  LastSampledDetails: string;
  Rating: number | null;
  PreviousInspections: KeyValuePair<number, string>[];
  Finance_DebtorExported_ENUM: DEBTOREXPORTED;
  NoOfVehicles: number | null;
  NoOfChairs: number | null;
  Flag_ICU: boolean | null;
  Flag_CSSD: boolean | null;
  Flag_AppliedPatientServicePanel: boolean | null;
  Flag_AcceptedPatientServicePanel: boolean | null;
  TypeOfAnaesthesia_IDs: number[];
  TypeOfAnaesthesia: string;
  ScopeOfService: string;
  SpecificInfrastructure: string;
  ProviderNumber: string;
  Leasee: Contact_Relation;
  Leasee_RID: number | null;
  Subcontractor: Contact_Relation;
  Subcontractor_RID: number | null;
  SubStatus_KWD: number | null;
  SubStatus_Name: string;
  SaveTriggers: PremisesUpdateTriggers[];
}
// #endregion PREMISES DTO

export enum Premises_Status {
  Unspecified = 0,
  Prelodgement = 2,
  Registered = 3,
  Closed = 4,
}

export interface PremisesBusinessActivity extends DTOBaseClass_Standard {
  PremisesBusinessActivity_ID: number;
  Name: string;
  Code: string;
  Substance: string;
  PreTreatmentRequired: string;
  IndustryDischargeJustification: string;
  Justification: string;
  ActivityScore: number;
  SubstanceScore: number;
  PreTreatmentScore: number;
  IndustryDischargeFactor: number;
}
export enum PremisesUpdateTriggers {
  UpdateProprietor,
  UpdatePrimaryContact,
  UpdateFoodSafetySupervisor,
  UpdatePoolContractor,
  UpdatePoolOperator,
  UpdateSiteAddress,
  UpdatePostalAddress,
  UpdatePremisesType,
  ClearOnFSSContact,
  UpdateLeasee,
  UpdateSubcontractor,
}
export interface Svc_Premises {
  Premises: Premises;
  OSFee: number | null;
  PremisesBalance: number | null;
  InspectionTypeName: string;
  LbtLastAuditType: string;
  LbtLastAuditCompliance: string;
}
export interface PremisesRegisterLovs {
  HealthArea: IKeyValuePacket[];
  SewageTreatmentPlan: IKeyValuePacket[];
  FSPTemplate: IKeyValuePacket[];
  SubStatus: IKeyValuePacket[];
  InspectionFrequency: IKeyValuePacket[];
  AuditFrequency: IKeyValuePacket[];
  PCBA: KeyValuePair<string, string>[];
  //For additional details
  WasteWater: IKeyValuePacket[];
  LiquorLicenceType: IKeyValuePacket[];
  PrivateWaterSupplyType: IKeyValuePacket[];
  TypeOfAnaesthesia: IKeyValuePacket[];
  EmployeeTier: IKeyValuePacket[];
}

export interface PremisesUIControl {
  LitProprietor: ElementControl;
  LitProprietorResidentialAddress: ElementControl;
  LbtProprietorAddress: ElementControl;
  LitProprietorAddress: ElementControl;
  LitProprietorPostalAddress: ElementControl;
  LitContact: ElementControl;
  LitAddress: ElementControl;
  IsContainerExist: ElementControl;
  LitPostalAddress: ElementControl;
  LitFSPContact: ElementControl;
  LitMultiTenancy: ElementControl;
  LitLeasee: ElementControl;
  LitSubContractor: ElementControl;
  TrDates: ElementControl;
  DivAuditDetails: ElementControl;
  DivInspectionDetails: ElementControl;
  DivSampleDetails: ElementControl;
  DivFSPTemplate: ElementControl;
  DivFSPContact: ElementControl;
  DivRegistrationDates: ElementControl;
  DivHealthArea: ElementControl;
  DivPCBA: ElementControl;
  DivFSSRequired: ElementControl;
  LitPremisesType: ElementControl;
  LitRisk: ElementControl;
  LitPremisesClassification: ElementControl;
  DdlInspectionFrequency: ElementControl;
  DtRegisteredFrom: ElementControl;
  DtRegisteredTo: ElementControl;
  DtRegisteredFromRequired: ElementControl;
  DtRegisteredToRequired: ElementControl;
  ShowFees: ElementControl;
  ShowInspections: ElementControl;
  ShowSamples: ElementControl;
  ShowRegisterCT: ElementControl;
  FieldTradingName: ElementControl;
  ShowRegisterSP: ElementControl;
  DivNoEmployees: ElementControl;
  DivNoUnits: ElementControl;
  TxtNoOfUnitsRequired: ElementControl;
  FieldNoOfUnits: ElementControl;
  FieldNoOfEmployees: ElementControl;
  TxtNoOfEmployeesRequired: ElementControl;
  DdlFSPTemplateRequired: ElementControl;
  DdlHealthArea: ElementControl;
  BtnDrinkWater: ElementControl;
  LbtLastInspectionType: ElementControl;
  LbtLastAuditType: ElementControl;
  LbtLastAuditCompliance: ElementControl;
}
export interface PremiseHandlerRequest {
  FormAction: Svc_FormAction_Premise;
  Premises: Premises;
  Args: any;
  UIControl: PremisesUIControl;
  PremiseLOVs: PremisesRegisterLovs;
  IsFirstTimeLoad: boolean;
}
export interface PremisesHandlerResponse {
  Premises: Premises;
  UIControl: PremisesUIControl;
  PremiseLOVs: PremisesRegisterLovs;
}
export enum Svc_FormAction_Premise {
  SystemInitialise,
  Form_PickProprietor,
  Form_PickPrimaryContact,
  Form_PickProprietorAddress,
  Form_PickPremisesAddress,
  Form_PickPostalAddress,
  Form_PickFSPContact,
  Form_PickMultiTenancyRegister,
  Form_SameAsProprietor,
  Form_SameAsPrimaryContact,
  Form_SameAsPremisesAddress,
  Form_PickLeasee,
  Form_PickSubcontractor,
  Form_InspectionFrequencyChange,
  Form_AuditFrequencyChange,
  Form_HealthAreaChanged,
  Form_PickPremisesType,
  Form_PickCategory,
}

//region ENUMS
export enum OfficerSelectionMethod {
  Standard = 0,
  ByArea = 1,
}
//#endregion

export interface IHMHandlerEventInitialData {
  premisesRegisterHandlerRequest: PremiseHandlerRequest;
  errorMsg: string;
}
type TPremisesHandler = IIdentityPacket<PremisesHandlerResponse>;
export type TApiPremisesHandlerResponse = APIResponse<TPremisesHandler>;
export type TApiPremisesHandler =
  | TApiPremisesHandlerResponse
  | APIResponseError;

export interface ApiPremisesTransferData {
  notifications: string[];
  premisesFormData: Svc_Premises;
  uiControl: PremisesUIControl | null;
  premiseLOVs: PremisesRegisterLovs;
}
export type TApiPremisesResponse = [
  APIResponse<IIdentityPacket<PremisesRegisterLovs>>,
  APIResponse<IIdentityPacket<Svc_Premises>>,
  APIResponse<boolean>
];

export interface Svc_HMPremisesType {
  ID: number;
  PremisesClassification: string;
  RiskClassification: string;
  RenewalMethod_Name: string;
  InspectionFrequency: number | null;
  RegistrationFee_Amount: number | null;
  RenewalFee_Amount: number | null;
  TransferFee_Amount: number | null;
  ApplicationFee_Amount: number | null;
  Flag_NoOfEmployees: boolean;
  Flag_NoOfUnits: boolean;
  Flag_NoOfBeds: boolean;
  Flag_TemporaryPremises: boolean;
  Flag_TobaccoRetail: boolean;
  Flag_FoodVehicle: boolean;
  Name: string;
  Description: string;
  Category: string;
  PremisesCount: number;
}

export enum Frequency {
  Unspecified = 0,
  Monthly = 1,
  ThreeMonthly = 3,
  SixMonthly = 6,
  NineMonthly = 9,
  Yearly = 12,
  EighteenMonthly = 18,
  TwoYearly = 24,
  ThreeYearly = 36,
  FourYearly = 48,
  FiveYearly = 60,
}

export enum SecondaryModeField {
  map_TRANDING_NAME = "TrandingName",
  map_REGISTRATION_NUMBER = "RegistrationNumber",
  map_PREMISES_TYPE = "PremisesType",
  map_PREMISES_CLASSIFICATION = "PremisesClassification",
  map_PREMISES_STREETNO = "PremisesStreetNo",
  map_PREMISES_STREETNAME = "PremisesStreetName",
  map_PREMISES_SUBURB = "PremisesSuburb",
  map_PREMISES_POSTCODE = "PremisesPostCode",
  map_PREMISES_AREA = "PremisesArea",
  map_PREMISES_LOCATIONDESCRIPTION = "PremisesLocationDescription",
  map_PREMISES_RISK = "PremisesRisk",
  map_DATE_LODGED = "Date_Lodged",
  map_COMMENTS = "Comments",
  map_NO_OF_EMPLOYEE = "NoOfEmployee",
  map_PROPRIETOR_COMPANYNAME = "ProprietorCompanyName",
  map_PROPRIETOR_COMPANY_NUMBER = "ProprietorCompanyNumber",
  map_PROPRIETOR_WORK_PHONE = "Proprietor_WorkPhone",
  map_PROPRIETOR_HOME_PHONE = "Proprietor_HomePhone",
  map_PROPRIETOR_MOBILE = "Proprietor_Mobile",
  map_PROPRIETOR_EMAIL = "Proprietor_Email",
  map_PROPRIETOR_POSTAL_ADDRESS = "ProprietorPostalAddress",
  map_TRADING_HOURS = "TradingHours",
  map_OFFICER = "Officer",
  map_ASSESSMENT_NO = "AssessmentNumber",
  map_ASSESSMENT_ID = "Assessment_ID",
  map_DEBTOR_NUMBER = "DebtorNumber",
}

export const enum SecondaryModeCheckField {
  BtnRegister,
  LbtAddress,
  LbtPremisesType,
  LbtProprietor,
  TxtRegistrationNo,
  TxtTradingName,
  TxtDebtorNo,
  LbtOfficer,
}

export interface IPremisesFormSecurity {
  AllowChangePremisesType?: boolean;
  AllowEditRegistration?: boolean;
  AllowOverrideInspFrequency?: boolean;
  AllowSaveAndEdit?: boolean;
  AllowDeleteInspection?: boolean;
  AllowRegisterPremisesButton?: boolean;
}

// Use for saving the init values
export interface IPremisesCCFormOptions {
  PremisesType: PremisesType;
  Date_RegistrationTo?: Date;
  Date_RegistrationFrom?: Date;
}

export enum HealthManagerMenu {
  Unspecified = 0,
  Premises = 1,
  PremisesRegister = 2,
  PreTreatmentRegister = 3,
  PremisesByPreTreatment = 4,
  PremisesByType = 5,
  PremisesBySuburb = 6,
  PremisesByArea = 7,
  PremisesByJurisdiction = 8,
  PremisesByRisk = 9,
  PremisesByOfficer = 10,
  PremisesByFSP = 11,
  PremisesByProperty = 12,
  PremisesByGrading = 13,
  PremisesByCategory = 14,
  PremisesByProprietor = 15,
  PremisesPCBA = 16,
  PremisesPrescribedAccom = 17,
  PremisesPools = 18,
  PremisesCoolingTowers = 19,
  DrinkingWater = 20,
  PremisesFastChoices = 21,
  PremisesFoodStalls = 22,
  PremisesFoodVehicles = 23,
  PremisesFoodHighRisk = 24,
  PremisesFBN_UnProcessed = 25,
  PremisesFBN_Processed = 26,
  PremisesFBN_Registered = 27,
  PremisesOSFees = 28,
  PremisesNonRenewable = 29,
  PremisesStreatrader = 30,
  PremisesPrelodgments = 31,
  PremisesClosures = 32,
  PremisesPendingTransfers = 33,
  PremisesDueConditions = 34,
  PremisesCompliedConditions = 35,
  PremisesAlerts = 36,
  PremisesRegisterPortalStatus = 37,
  PremisesScoresEndorse = 38,
  Tobacco = 45,
  PremisesTobacco = 46,
  PremisesTobaccoByType = 47,
  PremisesTobaccoBySuburb = 48,
  PremisesTobaccoByArea = 49,
  PremisesTobaccoByOfficer = 50,
  PremisesTobaccoPrelodgements = 51,
  PremisesTobaccoClosures = 52,
  PremisesTobaccoPendingTransfers = 53,
  HealthLicensing = 60,
  HealthLicensingRegister = 61,
  HealthLicensingByType = 62,
  HealthLicensingBySuburb = 63,
  HealthLicensingPrelodgments = 64,
  HealthLicensingClosures = 65,
  MultiTenancy = 70,
  RegisterMultiTenancy = 71,
  PremisesMultiTenancy = 72,
  Enquiries = 80,
  RegisterEnquiries = 81,
  Renewals = 90,
  RenewalsByDueStatus = 91,
  RenewalsByType = 92,
  RenewalsReqFee = 93,
  RenewalsReqNotice = 94,
  RenewalsNoticesent = 95,
  RenewalsReqNotice_ACT = 96,
  RenewalsNoticesent_ACT = 97,
  RenewalsNoticeReceived = 98,
  RenewalsFeesOutstanding = 99,
  RenewalsReqCertificateFeePaid = 100,
  RenewalsReqCertificate = 101,
  InspectionSchedule = 110,
  PremisesMyInspSchedule = 111,
  PremisesMyInspScheduleCalendar = 112,
  PremisesInspScheduleAll = 113,
  PremisesNotInspected = 114,
  PremisesInspDue = 115,
  PremisesInspScheduleAudits = 116,
  Inspections = 120,
  InspectionsByDate = 121,
  InspectionsByType = 122,
  InspectionsByOfficer = 123,
  InspectionsByMonth = 124,
  InspectionsByArea = 125,
  InspectionsSupervisor = 126,
  InspectionsAssessmentsByDate = 127,
  InspectionsAuditsByDate = 128,
  InspectionsOSNCItems = 129,
  Complaints = 140,
  ComplaintsByDate = 141,
  ComplaintsByStatus = 142,
  ComplaintsByCategory = 143,
  ComplaintsByOfficer = 144,
  ClosedComplaintsByOfficer = 145,
  ComplaintsByDateUnrelated = 146,
  ComplaintsByStatusUnrelated = 147,
  ComplaintsByCategoryUnrelated = 148,
  ComplaintsByOfficerUnrelated = 149,
  ClosedComplaintsByOfficerUnrelated = 150,
  EventsByRefNo = 151,
  AllEventsByCategory = 152,
  AllEventsByStatus = 153,
  Notices = 160,
  NoticesByDate = 161,
  NoticesByDueStatus = 162,
  NoticesByOfficer = 163,
  Samples = 170,
  SamplesByDate = 171,
  SamplesByType = 172,
  SamplesByOfficer = 173,
  SamplesByMonth = 174,
  SamplesByStatus = 175,
  SamplesBySurvey = 176,
  SampleSurveys = 177,
  PremisesNotSampled = 178,
  Recalls = 185,
  RecallsByTradingName = 186,
  RecallsByMonth = 187,
  RecallsByNumber = 188,
  Finance = 195,
  DebtorSummaryAll = 196,
  DebtorSummaryOutstanding = 197,
  FeesOutstanding = 198,
  FeesPaid = 199,
  FeesNotSentToFinance = 200,
  FeesSentToFinance = 201,
  ExportedDebtors = 202,
  NotExportedDebtors = 203,
  InvoicesNotSentToFinance = 204,
  InvoicesSentToFinance = 205,
  FeesAll = 206,
  FSR = 210,
  FSRLog = 211,
  PremisesByFSRType = 212,
  FSRPremisesTypes = 213,
  FSRInspectionTypes = 214,
  FSRNoticeTypes = 215,
  FSRComplaintTypes = 216,
  FSRKeywords = 217,
  MAVReports = 225,
  PremisesMAVTobaccoRetailers = 256,
  PremisesMAVTobaccoInspections = 257,
  PremisesMAVVendingMachines = 258,
  PremisesMAVEatInFacilities = 259,
  PremisesMAVOutdoorDining = 260,
  PremisesMAVLicenced = 261,
  Administration = 270,
  PremisesTypes = 271,
  Classifications = 272,
  RiskClass = 273,
  BusinessActivities = 274,
  VolumeScores = 275,
  AdminNCItems = 276,
  AdminAFSAComments = 277,
  Conditions = 278,
  Settings = 279,
  RecycleBin = 290,
  RecycleBinPremises = 291,
  RecycleBinPremisesTypes = 292,
  RecycleBinFees = 293,
  RecycleBinInspections = 294,
  RecycleBinSamples = 295,
  PremisesRegisterEHA = 300,
  PremisesRegisterLight = 301,
  PremisesTobaccoLight = 302,
  PremisesRegisterWaterAuthority = 303,
}
