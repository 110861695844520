import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { ChecklistButton } from "@app/core/checklist/components/button/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { InspectionForm } from "@app/core/inspections/[id]/components/child-screens/general/_index";
import { InspectionContactsTab } from "@app/core/inspections/[id]/components/reference-sidebar/contacts/_index";
import { InspectionDetailsTab } from "@app/core/inspections/[id]/components/reference-sidebar/details/_index";
import { InspectionHistoryTab } from "@app/core/inspections/[id]/components/reference-sidebar/history/_index";
import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { useInspectionsStore } from "@app/core/inspections/[id]/store";
import { renderTitleBanner } from "@app/core/inspections/[id]/util";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useGlobalStore } from "@common/stores/global/store";
import { mapEnum, renderBubbleUps } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";

export const ExistManageInspection = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    inspections,
    responseLoadError,
    loadInspections,
    onSubmit,
    isInactive,
  } = useInspectionsStore();
  const { currentUserInfo } = useGlobalStore();
  const isNew = useIsNew();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Inspection,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  const managePageUrl = window.location.pathname;
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: inspections?.Description ?? "",
      LinkUrl: managePageUrl,
      LinkText: `Inspections - ${id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE?.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Inspection,
      Record_ID: id ? +id : 0,
    },
  ];

  const recordType = inspections?._BubbleUps?.find(
    (item) =>
      mapEnum(item.BubbleUpType_ENUM, BubbleUpType) === BubbleUpType?.Parent
  )?.SourceIdentifier?._RecordSourceType_ENUM;
  const recordId = inspections?._BubbleUps.find(
    (item) =>
      mapEnum(item.BubbleUpType_ENUM, BubbleUpType) === BubbleUpType?.Parent
  )?.SourceIdentifier?._RecordSource_ID;

  const handleOnReload = () => {
    loadInspections(parseInt(id), isNew);
  };

  const [showSlideBar, setShowSlideBar] = useState(true);

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={parseInt(id)} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={parseInt(id)}
          recordType={RECORDTYPE.CORE_Inspection}
        />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={parseInt(id)} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.CORE_Inspection} />
      ),
    },
  ];

  const checklistName = useMemo(
    () =>
      inspections?.InspectionType?.InspectionTypeChecklist_Label &&
      !isEmpty(inspections?.InspectionType?.InspectionTypeChecklist_Label)
        ? inspections?.InspectionType?.InspectionTypeChecklist_Label
        : undefined,
    [inspections?.InspectionType?.InspectionTypeChecklist_Label]
  );

  return (
    <>
      <Loading isLoading={isLoading || isLoadingPermission} isFullScreen />
      <FormNavigation title="Inspection" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={handleOnReload}
        />
      ) : (
        <>
          <FormTitle
            title={renderTitleBanner(
              inspections?.InspectionType_Name,
              inspections?.Compliance?.Name
            )}
          />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                disabled={isInactive}
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <ChecklistButton
                  disabled={isInactive}
                  recordSourceID={inspections?.Inspection_ID ?? 0}
                  checklistID={
                    inspections?.InspectionType?.InspectionTypeChecklist_ID ?? 0
                  }
                  checklistName={checklistName}
                />
              </CCNavButton>,
              <CCNavButton
                disabled={
                  isInactive ||
                  !checkPermissions([
                    FormAction.CORE_ALLOW_SAVE,
                    FormAction.CORE_ALLOW_EDIT,
                  ])
                }
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
              <CCNavButton title={"Add"} type="sub" disabled={isInactive}>
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Inspection}
                  bubbleUps={inspections?._BubbleUps}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)
                  }
                />
                <AdditionalContactButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Inspection}
                  bubbleUps={inspections?._BubbleUps}
                />
                <AddAttachmentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Inspection}
                  bubbleUps={inspections?._BubbleUps}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
                  }
                />
                <AddCommentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Inspection}
                  bubbleUps={renderBubbleUps(
                    recordId ?? 0,
                    recordType,
                    parseInt(id),
                    RECORDTYPE.CORE_Inspection
                  )}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
                  }
                />
                <AddDocumentButton
                  // TODO: change dataSetFilter
                  dataSetFilter={
                    MAILMERGEDATASET?.CUSTOMERSERVICE_Event_Inspections
                  }
                  recordType={RECORDTYPE.CORE_Inspection}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={parseInt(id)}
                  bubbleUps={inspections?._BubbleUps}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
                  }
                />
                {/* TODO: Implement later */}
                {/* <CCNavButton title={"Fees"} />
                <AddNoticeWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Inspection}
                  bubbleUps={renderBubbleUps(
                    recordId ?? 0,
                    recordType,
                    parseInt(id),
                    RECORDTYPE.CORE_Inspection
                  )}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_NOTICE)
                  }
                />
                <CCNavButton title={"Interview"} />
                <CCNavButton title={"Samples"} /> */}
              </CCNavButton>,
              // TODO: Implement later
              // <CCNavButton
              //   title={"More Options"}
              //   type="more"
              //   disabled={isInactive}
              // >
              //   <CCNavButton title={"Add NC Item"} />
              //   <CCNavButton title={"AFSA"} />
              //   <CCNavButton title={"Add Temp Recorded"} />
              //   <CCNavButton title={"Add FSP Observed"} />
              // </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {inspections && <InspectionForm />}
                <TabTable
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Inspection}
                  initialPanels={listPanelBar}
                />
                <TabTable
                  className="cc-tab-table-spacing"
                  id={recordId}
                  recordType={mapEnum(recordType, RECORDTYPE)}
                  initialPanels={[
                    {
                      title: "Inspections",
                      tabType: TabTableType.Inspections,
                      component: (
                        <Inspections
                          id={recordId}
                          recordType={mapEnum(recordType, RECORDTYPE)}
                          reloadAfterDeleted={handleOnReload}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <InspectionDetailsTab /> },
                    { title: "Contacts", component: <InspectionContactsTab /> },
                    {
                      title: "History",
                      component: <InspectionHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
