import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { useRefreshData } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useRefreshData/useRefreshData";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import {
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  getInitialDataModifySupplementary,
  postModifySupplementary,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/modify-supplementary/api";
import { postNewSupplementary } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/api";
import { DTO_Create_Supplementary_LOVs_Response } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import { DetailsFormStep } from "./components/form-elements/_index";
import {
  DTO_Workflow_Supplementary_Modify,
  EKeysOfSteps,
  keysOfSendSteps,
} from "./model";

interface INewSupplementaryDialog {
  onClose: () => void;
  isFromActionList?: boolean;
  draftId?: number;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  supplementaryId: number;
}

export const ModifySupplementaryDialog = observer(
  ({
    onClose,
    isFromActionList = false,
    dataFromActionList,
    isSaveOnNextStep = false,
    prefixTitle,
    suffixTitle,
    supplementaryId,
  }: INewSupplementaryDialog) => {
    const [modifySupplementaryInitData, setModifySupplementaryInitData] =
      useState<DTO_Workflow_Supplementary_Modify>();
    const { currentFormTitle } = useCommonProductStore();
    const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
    const { dataForCancelDialog, setDataForCancelDialog } =
      useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { pushNotification } = useCCAppNotificationStore();

    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { refreshPage } = useRefreshData(
      modifySupplementaryInitData?.WorkflowHeader
    );

    const {
      isIncompleteMode,
      isReadOnly,
      statusBadge,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);

    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    // Keep for future use
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const steps: IStep[] = [
      {
        label: "Details",
        component: DetailsFormStep,
        options: {
          dataForCancelDialog: dataForCancelDialog,
          isReadOnly,
        },
        visible: true,
        key: EKeysOfSteps.Details,
      },
    ];

    const handleSubmit = async (
      data: DTO_Workflow_Supplementary_Modify,
      buttonId?: string
    ) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
        default:
          break;
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_Supplementary_Modify
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postModifySupplementary,
        dataSendBack: payload,
        defaultSuccessMessage: "Supplementary was modified successfully.",
        defaultErrorMessage: "Modify supplementary could not be sent back.",
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_Supplementary_Modify
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postModifySupplementary,
        dataReallocate: payload,
        defaultSuccessMessage: "Modify supplementary reallocated successfully.",
        defaultErrorMessage: "Reallocate modify supplementary failed.",
      });
    };

    /**
     * handle approve process
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_Supplementary_Modify
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_Supplementary_Modify> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            refreshPage(() =>
              pushNotification({
                title:
                  e?.Notification ??
                  "Modify supplementary was approved successfully.",
                type: "success",
              })
            );
          },
          defaultFailedMessage: "Modify supplementary could not be approved.",
          modeProcess: WorkflowProcessMode.Approve,
        };
      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps, setLoading);
    };
    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postModifySupplementary,
          dataReject: data,
          defaultSuccessMessage:
            "Modify supplementary was rejected successfully.",
          defaultErrorMessage: "Modify supplementary could not be rejected.",
        });
      } else {
        onClose();
      }
    };

    const handleCancelButton = (data: DTO_Workflow_Supplementary_Modify) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postNewSupplementary,
          dataCancel: data,
          defaultSuccessMessage:
            "The modify supplementary was cancelled successfully.",
          defaultErrorMessage: "Modify supplementary cancel failed.",
        });
      } else {
        onClose();
      }
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = (payload: DTO_Workflow_Supplementary_Modify) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_Supplementary_Modify> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ?? `Modify supplementary parked successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Park modify supplementary failed`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingPark(false);
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_Supplementary_Modify
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_Supplementary_Modify> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              "Modify supplementary was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Modify supplementary could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    const processData = (data: DTO_Workflow_Supplementary_Modify) => {
      const newData = cloneDeep(data);
      let workflowHeader: any = {
        ...modifySupplementaryInitData?.WorkflowHeader,
      };

      //Officer process data
      //field update: WD_Assessment_Group_Id
      const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
        officerUtilProcessing.processData(newData, EKeysOfSteps.Details);
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;

      let workflowDetail: any = {};
      const sendSteps = pickBy(newData, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          const dataStep = { ...value };
          if (key === EKeysOfSteps.Details) {
            workflowDetail = {
              ...workflowDetail,
              ...dataStep,
            };
          } else {
            workflowDetail[key as keyof DTO_Workflow_Supplementary_Modify] =
              dataStep;
          }
        }
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleNextButton = async (
      data: DTO_Workflow_Supplementary_Modify
    ) => {
      setIsLoadingOnNext(true);

      return await handleSaveAndNext(processData(data));
    };

    const handleSaveAndNext = async (
      payload: DTO_Workflow_Supplementary_Modify,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ) => {
      const response = await postModifySupplementary(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = "Supplementary saved successfully.";
      const defaultFailedMessage =
        "The modify supplementary process could not be saved";

      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            // get new data from draftId after first save
            if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
              setModifySupplementaryInitData({
                WorkflowDetail: response.data?.ReturnData?.WorkflowDetail,
                WorkflowHeader: response.data?.ReturnData?.WorkflowHeader,
              });
            }
          }
          // TODO: Show notification after reloading the step -> enhance later
          if (isRefreshWorkflowData) {
            getWorkflowData().then(() => {
              notificationFormStepRef?.current
                ?.getNotificationFormStep()
                ?.current?.pushNotification({
                  title: response?.data?.Notification ?? defaultSuccessMessage,
                  type: "success",
                });
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_Supplementary_Modify>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;

      const response = await postModifySupplementary(modeProcess, payload);

      if (isSuccessResponse(response)) {
        setLoading();
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const getWorkflowData = async () => {
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      getInitialDataModifySupplementary(
        supplementaryId ?? 0,
        workflowDraftId
      ).then((response) => {
        if (Array.isArray(response)) {
          const [lovsSupplementary, workflowData] = response;
          if (
            isSuccessResponse(lovsSupplementary) &&
            isSuccessResponse(workflowData) &&
            lovsSupplementary?.data &&
            workflowData?.data
          ) {
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            setCreateSupplementaryLOVs(
              lovsSupplementary.data?.dtoCreate_Supplementary_LOVs
            );
            let newData: DTO_Workflow_Supplementary_Modify = {
              WorkflowDetail: {
                ...workflowData.data?.WorkflowDetail,
              },
              WorkflowHeader: workflowData.data?.WorkflowHeader,
            };
            setModifySupplementaryInitData(newData);

            if (workflowData.data?.WorkflowHeader) {
              setWorkflowDraftId(
                workflowData.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
          } else {
            let responseError: APIResponse<
              | DTO_Create_Supplementary_LOVs_Response
              | DTO_Workflow_Supplementary_Modify
              | ResponsePacket
            > = lovsSupplementary;
            if (!isSuccessResponse(workflowData)) responseError = workflowData;
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed.",
              },
            });
          }
        } else {
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: response?.status,
              error: "Load workflow failed.",
            },
          });
        }
      });
    };

    const initialValue = useMemo(() => {
      const workflowDetail = modifySupplementaryInitData?.WorkflowDetail;
      const officerInitData = officerUtilProcessing.processDataInit(
        modifySupplementaryInitData
      );
      const initDetails = {
        ...workflowDetail,
        SupplementaryDate:
          modifySupplementaryInitData?.WorkflowDetail?.SupplementaryDate ??
          new Date(), //Default Current Date
        ...officerInitData,
      };
      return {
        [EKeysOfSteps.Details]: initDetails,
      };
      // eslint-disable-next-line
    }, [modifySupplementaryInitData]);

    /**
     * process title dialog
     */
    const titleHeader = useMemo(() => {
      const formId =
        modifySupplementaryInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Modify Supplementary";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, modifySupplementaryInitData]);

    useEffectOnce(() => {
      getWorkflowData();
    });

    const handleConfirmFinishProcess = (
      data: DTO_Workflow_Supplementary_Modify
    ) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess(processData(data)),
        confirmMessage: `The supplementary will be updated based on the information provided. Are you sure you want to submit? The Supplementary update will run in the background and the result will be updated shortly.`,
      });
    };

    const handleFinishProcess = async (
      payload: DTO_Workflow_Supplementary_Modify
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_Supplementary_Modify> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          const notificationContent: IAppNotificationItemAddProps = {
            title: e?.Notification ?? `Supplementary modified successfully.`,
            type: "success",
          };
          refreshPage(() => pushNotification(notificationContent));
        },
        defaultFailedMessage: `Modify supplementary failed.`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };
    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        initialSteps={steps}
        initialValues={initialValue}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            badge={statusBadge}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled || isLoadingPark
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Cancel}
                      disabled={
                        isLoadingApprove || isLoadingOnNext || isLoadingPark
                      }
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {isToBeApprovalMode &&
                    modifySupplementaryInitData?.WorkflowHeader
                      ?.OfficerCanApprove && (
                      <>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.SendBack}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Send Back
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reallocate}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reallocate
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Approve}
                          disabled={
                            isLoadingApprove || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                          iconClass={
                            isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reject}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {isToBeApprovalMode || isReadOnly ? (
                    !renderProps.isLastStep && (
                      <Button
                        themeColor="primary"
                        id="cc-next-step-button"
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )
                  ) : (
                    <Button
                      themeColor="primary"
                      id={renderProps.nextButton.idButton}
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
