import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfSteps {
  NewSupplementary = "NewSupplementary",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendSteps = [EKeysOfSteps.NewSupplementary];

export interface DTO_Workflow_New_Supplementary {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_New_Supplementary;
}

export interface DTO_WorkflowDetail_New_Supplementary {
  Supplementary_Id: number;
  NewSupplementary: DTO_Supplementary_Create;
}

export interface DTO_Supplementary_Create {
  SupplementaryId: number | null;
  SupplementaryType: number;
  InstalmentPlanId: number | null;
  Name: string;
  Reference: string;
  Date: string;
  Notes: string;
  IsComplete: boolean | null;
  CreatedOn: string | null;
  CreatedBy: string;
  VM2000_Id: number | null;
  TransferRemainingBalance: boolean;
  BalanceTransferMethodId: number;
  BalanceTransferValuationType: number | null;
  UseExistingInstalmentPlan: boolean;
}

export interface DTO_Create_Supplementary_LOVs_Response {
  dtoCreate_Supplementary_LOVs: DTO_New_Supplementary_LOVs;
}

export interface DTO_New_Supplementary_LOVs {
  SupplementaryType: DTO_LOV_SupplementaryType[];
  InstallmentType: DTO_LOV[];
  AssessmentType: DTO_LOV[];
  ValuationTypeTobeUsed: DTO_LOV[];
  Workflow_Memo_Category: DTO_LOV[];
}

export interface DTO_LOV_SupplementaryType {
  Code: string;
  Name: string;
  IsInstalmentPlanHidden: boolean;
  IsRaiseCharge: boolean;
}

export interface INewSuplementaryFormStep {
  [EKeysOfSteps.NewSupplementary]: INewSupplementary;
  [EKeysOfSteps.Comments]: any;
  [EKeysOfSteps.Documents]: any;
}

export interface INewSupplementary {
  SupplementaryId?: number | null | undefined;
  SupplementaryType?: number;
  InstalmentPlanId?: number | null | undefined;
  Name?: string | undefined;
  Reference?: string | undefined;
  Date: string | Date;
  Notes?: string | undefined;
  IsComplete?: boolean | null | undefined;
  CreatedOn?: string | null | undefined;
  CreatedBy?: string | undefined;
  VM2000_Id?: number | null | undefined;
  TransferRemainingBalance: boolean;
  BalanceTransferMethodId?: number | undefined;
  BalanceTransferValuationType?: number | null | undefined;
  UseExistingInstalmentPlan?: boolean | undefined;
  _options?: any;
}

export interface IWorkflowNewSupplementary {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_New_Supplementary;
  Component_Id?: number;
}
