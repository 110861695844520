import { postSaveDynamicQuestion } from "@app/core/checklist/components/dialog/dynamic-question/api";
import {
  dynamicQuestionFieldName,
  DynamicQuestionResponse,
} from "@app/core/checklist/components/dialog/dynamic-question/model";
import {
  refactorCheckBoxValues,
  refactorListData,
  refactorListDataCheckBox,
} from "@app/core/checklist/components/dialog/dynamic-question/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ChecklistAnswerType } from "@common/constants/enumerations";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCCheckboxGroup } from "@components/cc-checkbox-group/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import "./_index.scss";

export interface IDynamicQuestionDialogProps {
  onClosePopup: () => void;
  dynamicQuestionResponse: object;
  responseLoadError?: APIResponseError;
  isLoading: boolean;
  loadDynamicQuestion: () => Promise<void>;
  isFromAction?: boolean;
}

export const DynamicQuestionDialog = observer(
  ({
    onClosePopup,
    dynamicQuestionResponse,
    responseLoadError,
    isLoading = false,
    loadDynamicQuestion,
    isFromAction = false,
  }: IDynamicQuestionDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      setIsLoadingSubmit(true);
      const response = await postSaveDynamicQuestion({
        Responses: Object.values(event.values),
        IsFromAction: isFromAction,
      });
      setIsLoadingSubmit(false);
      if (isSuccessResponse(response)) {
        onClosePopup();
        appNotificationStore.pushNotification({
          title: "Answers successfully saved.",
          type: "success",
        });
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Answers could not be saved.",
          type: "error",
          description: response.data?.Errors ?? response.error,
        });
      }
    };

    const renderDynamicQuestion = (
      onChange: (
        name: string,
        options: {
          value: any;
        }
      ) => void,
      valueGetter: (name: string) => any
    ) => {
      let dynamicQuestionList: any[] = [];
      const dynamicQuestionResponseArray = Object.values(
        dynamicQuestionResponse
      );

      if (dynamicQuestionResponseArray?.length > 0) {
        dynamicQuestionList = dynamicQuestionResponseArray.map(
          (dynamicQuestion: DynamicQuestionResponse) => {
            switch (dynamicQuestion.ChecklistAnswerType_ENUM) {
              case ChecklistAnswerType.Label:
                return (
                  <div
                    className="cc-field"
                    key={dynamicQuestion.ChecklistQuestion_ID}
                  >
                    <CCLabel title={dynamicQuestion.Question} />
                  </div>
                );
              case ChecklistAnswerType.TextBox:
                return (
                  <div
                    className="cc-field"
                    key={dynamicQuestion.ChecklistQuestion_ID}
                  >
                    <CCLabel
                      title={dynamicQuestion.Question}
                      isMandatory={!isFromAction}
                    />
                    <Field
                      name={`${dynamicQuestionFieldName.TextBox}${dynamicQuestion.ChecklistQuestion_ID}.AnswerResponse`}
                      component={CCInput}
                      readOnly={isLoadingSubmit}
                      validator={!isFromAction ? requiredValidator : undefined}
                    />
                  </div>
                );
              case ChecklistAnswerType.DatePicker:
                return (
                  <div
                    className="cc-field"
                    key={dynamicQuestion.ChecklistQuestion_ID}
                  >
                    <CCLabel title={dynamicQuestion.Question} />
                    <Field
                      name={`${dynamicQuestionFieldName.DatePicker}${dynamicQuestion.ChecklistQuestion_ID}.AnswerResponse`}
                      component={CCDatePicker}
                      disabled={isLoadingSubmit}
                      onChange={(event: DatePickerChangeEvent) => {
                        onChange(
                          `${dynamicQuestionFieldName.DatePicker}${dynamicQuestion.ChecklistQuestion_ID}.AnswerResponse`,
                          { value: event.value?.toJSON() }
                        );
                      }}
                      format={DATE_FORMAT.DATE_CONTROL}
                    />
                  </div>
                );
              case ChecklistAnswerType.RadioButton:
                return (
                  <div
                    className="cc-field"
                    key={dynamicQuestion.ChecklistQuestion_ID}
                  >
                    <CCLabel
                      title={dynamicQuestion.Question}
                      isMandatory={!isFromAction}
                    />
                    <Field
                      name={`${dynamicQuestionFieldName.RadioButton}${dynamicQuestion.ChecklistQuestion_ID}.AnswerResponse`}
                      data={refactorListData(dynamicQuestion.AnswerField)}
                      component={CCRadioGroup}
                      layout={"vertical"}
                      disabled={isLoadingSubmit}
                      validator={!isFromAction ? requiredValidator : undefined}
                    />
                  </div>
                );
              case ChecklistAnswerType.CheckBox:
                return (
                  <div
                    className="cc-field"
                    key={dynamicQuestion.ChecklistQuestion_ID}
                  >
                    <CCLabel
                      title={dynamicQuestion.Question}
                      isMandatory={!isFromAction}
                    />
                    <Field
                      className="cc-checkbox-question"
                      name={`${dynamicQuestionFieldName.CheckBox}${dynamicQuestion.ChecklistQuestion_ID}.AnswerResponse`}
                      data={refactorListDataCheckBox(
                        dynamicQuestion.AnswerField,
                        false
                      )}
                      component={CCCheckboxGroup}
                      value={refactorListDataCheckBox(
                        valueGetter(
                          `${dynamicQuestionFieldName.CheckBox}${dynamicQuestion.ChecklistQuestion_ID}.AnswerResponse`
                        ),
                        true
                      )}
                      onDataChange={(value: any) => {
                        onChange(
                          `${dynamicQuestionFieldName.CheckBox}${dynamicQuestion.ChecklistQuestion_ID}.AnswerResponse`,
                          { value: refactorCheckBoxValues(value) }
                        );
                      }}
                      disabled={isLoadingSubmit}
                      validator={!isFromAction ? requiredValidator : undefined}
                    />
                  </div>
                );
            }
            return null;
          }
        );
      }
      return dynamicQuestionList;
    };

    return (
      <Form
        initialValues={dynamicQuestionResponse}
        key={JSON.stringify(dynamicQuestionResponse)}
        onSubmitClick={handleSubmit}
        render={({
          onSubmit,
          modified,
          valid,
          valueGetter,
          onChange,
        }: FormRenderProps) => {
          return (
            <FormElement>
              <CCDialog
                maxWidth="40%"
                maxHeight="80%"
                height={"auto"}
                disabled={isLoading}
                titleHeader={"Dynamic Questions"}
                onClose={onClosePopup}
                isLoading={isLoading}
                bodyElement={
                  <>
                    {responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={loadDynamicQuestion}
                      />
                    ) : (
                      <div className="cc-form">
                        <CCLocalNotification ref={notificationRef} />
                        <section className="cc-field-group">
                          <div className="cc-form-cols-1">
                            {renderDynamicQuestion(onChange, valueGetter)}
                          </div>
                        </section>
                      </div>
                    )}
                  </>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onClosePopup();
                      }}
                      disabled={isLoading || isLoadingSubmit}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !modified || !valid || isLoading || isLoadingSubmit
                      }
                      themeColor="primary"
                      iconClass={
                        isLoadingSubmit ? "fas fa-spinner fa-spin" : ""
                      }
                      onClick={onSubmit}
                      className={"cc-dialog-button"}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
